import React, { useEffect, useState } from "react";
import AdminMenu from "../components/AdminMenu";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Modal } from "antd";
import Swal from "sweetalert2";


const baseUrl = `${process.env.REACT_APP_API}`;

const CreateCategory = () => {
  const [categories, setCategories] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState(null);

  const formik = useFormik({
    initialValues: {
      name: "",
      photo: null,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Category name is required"),
      photo: Yup.mixed().required("Photo is required"),
    }),
    onSubmit: async (values) => {
      try {
        const categoryData = new FormData();
        categoryData.append("name", values.name);
        categoryData.append("photo", values.photo);

        const { data } = await axios.post(
          `${baseUrl}/api/v1/category/create-category`,
          categoryData
        );
        if (data?.success) {
          formik.resetForm();
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: `${values.name} Created`,
          });
          getAllCategory();
        }
      } catch (error) {
        // Handle errors
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: `${values.name} Failed to create`,
        });
        getAllCategory();
      }
    },
  });

  const getAllCategory = async () => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/api/v1/category/get-category`
      );
      if (data.success) {
        setCategories(data.category);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    getAllCategory();
  }, []);
  //delete category
  const handleDelete = async (id) => {
    // Show a confirmation dialog using SweetAlert2
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const { data } = await axios.delete(
            `${baseUrl}/api/v1/category/delete-category/${id}`
          );

          if (data.success) {
            // Display success message
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "success",
              title: "Category deleted successfully",
            });

            getAllCategory();
          } else {
            // Display error message
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "error",
              title: "Failed to delete",
            });
          }
        } catch (error) {
          // Handle errors
        }
      }
    });
  };

  const updateFormik = useFormik({
    initialValues: {
      name: "",
      photo: null,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Category name is required"),
      photo: Yup.mixed().required("Photo is required"),
    }),
    onSubmit: async (values) => {
      try {
        const categoryData = new FormData();
        categoryData.append("name", values.name);
        if (values.photo) {
          categoryData.append("photo", values.photo);
        }

        const { data } = await axios.put(
          `${baseUrl}/api/v1/category/update-category/${selected._id}`,
          categoryData
        );

        if (data?.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: `${values.name} Updated`,
          });

          setVisible(false); // Close the modal
          getAllCategory();
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: `${values.name} Not Updated`,
          });
        }
      } catch (error) {
        // Handle errors
      }
    },
  });

  const handleUpdateCategoryClick = (c) => {
    setSelected(c);
    setVisible(true);
    updateFormik.setValues({
      name: c.name,
      photo: null, // Reset the photo field to null
    });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <AdminMenu />

        <div className="col-md-9 my-4">
          <div className="row">
            <h4>Create New Category</h4>
            <br />
            <div className="col-md-6">
              <div className="m-1 w-75">
                <form onSubmit={formik.handleSubmit}>
                  <div className="mb-3">
                    <input
                      type="text"
                      name="name"
                      value={formik.values.name}
                      placeholder="Enter Category Name"
                      className="form-control"
                      onChange={formik.handleChange}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className="text-danger">{formik.errors.name}</div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label className="btn btn-outline-secondary col-md-12">
                      {formik.values.photo
                        ? formik.values.photo.name
                        : "Select Photo"}
                      <input
                        type="file"
                        name="photo"
                        accept="image/*"
                        onChange={(event) => {
                          formik.setFieldValue(
                            "photo",
                            event.currentTarget.files[0]
                          );
                        }}
                        hidden
                      />
                    </label>
                    {formik.touched.photo && formik.errors.photo && (
                      <div className="text-danger">{formik.errors.photo}</div>
                    )}
                  </div>

                  <div className="mb-3">
                    <button type="submit" className="btn btn-primary">
                      CREATE CATEGORY
                    </button>
                  </div>

                  <div className="col-md-6">
                    <div className="mb-3">
                      {formik.values.photo && (
                        <div className="text-center">
                          <img
                            src={URL.createObjectURL(formik.values.photo)}
                            alt="product_photo"
                            height={"200px"}
                            className="img img-responsive"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="row">
              <div className="col-sm-12 col-lg-12">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Photo</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {categories?.map((c) => (
                      <>
                        <tr>
                          <td key={c._id}>{c.name}</td>
                          <td>
                            <img
                              key={c.id}
                              alt={c.name}
                              className="admin-img"
                              src={`${baseUrl}/api/v1/category/category-photo/${c._id}`}
                            />
                          </td>
                          <td>
                            <button
                              className="btn btn-sm btn-primary ms-2"
                              onClick={() => {
                                handleUpdateCategoryClick(c);
                              }}
                            >
                              Update
                            </button>

                            <button
                              className="btn btn-sm btn-danger ms-2"
                              onClick={() => {
                                handleDelete(c._id);
                              }}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
              <Modal
                onCancel={() => setVisible(false)}
                footer={null}
                visible={visible}
              >
                <form onSubmit={updateFormik.handleSubmit}>
                  <div className="mb-3">
                    <input
                      type="text"
                      name="name"
                      value={updateFormik.values.name}
                      placeholder="Category Name"
                      className="form-control"
                      onChange={updateFormik.handleChange}
                    />
                    {updateFormik.touched.name && updateFormik.errors.name && (
                      <div className="text-danger">
                        {updateFormik.errors.name}
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label className="btn btn-outline-secondary col-md-12">
                      {updateFormik.values.photo
                        ? updateFormik.values.photo.name
                        : "Select Photo"}
                      <input
                        type="file"
                        name="photo"
                        accept="image/*"
                        onChange={(event) => {
                          updateFormik.setFieldValue(
                            "photo",
                            event.currentTarget.files[0]
                          );
                        }}
                        hidden
                      />
                    </label>
                    {updateFormik.touched.photo &&
                      updateFormik.errors.photo && (
                        <div className="text-danger">
                          {updateFormik.errors.photo}
                        </div>
                      )}
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      {updateFormik.values.photo && (
                        <div className="text-center">
                          <img
                            src={
                              updateFormik.values.photo instanceof File
                                ? URL.createObjectURL(updateFormik.values.photo)
                                : `${baseUrl}/api/v1/category/category-photo/${selected._id}`
                            }
                            alt="product_photo"
                            height={"200px"}
                            className="img img-responsive"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mb-3">
                    <button type="submit" className="btn btn-primary">
                      UPDATE CATEGORY
                    </button>
                  </div>
                </form>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCategory;
