import React, { useState, useEffect } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { BsGeoAlt } from "react-icons/bs";
import { Link, NavLink } from "react-router-dom";
import MainLogo from "../img/logo-main.png";
import ToggleDark from "../img/toggle-icon-dark.svg";
import axios from "axios";
import toast from "react-hot-toast";

const baseUrl = `${process.env.REACT_APP_API}`;

function ConSidebar() {
  const [categories, setCategories] = useState([]);
  const [toggled, setToggled] = useState(false);

  //get all cat
  const getAllCategory = async () => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/api/v1/category/get-category`
      );
      if (data?.success) {
        setCategories(data?.category);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something wwent wrong in getting catgeory");
    }
  };

  useEffect(() => {
    getAllCategory();
  }, []);

  // change logo of navbar

  // const [logo, setLogo] = useState({
  //       src: "",
  // })
  // const ChangeLogo = () => setLogo(window.scrollY > 50)
  // useEffect(() => {
  //       window.addEventListener("scroll", ChangeLogo);
  // }, []);

  // change logo of navbar

  //change color of navbar

  // const [color, setColor] = useState(false)
  // const changeColor = () => {
  //       if (window.scrollY >= 50) {
  //             setColor(true)
  //       }
  //       else
  //             setColor(false)
  // }
  // window.addEventListener('scroll', changeColor)

  //change color of navbar

  return (
    <>
      <Sidebar
        backgroundColor="#ffff"
        onBackdropClick={() => setToggled(false)}
        toggled={toggled}
        breakPoint="always"
      >
        <div className="">
          <h4 className="p-4">Categories</h4>
        </div>

        <Menu
          menuItemStyles={{
            button: ({ level, active, disabled }) => {
              if (level === 0)
                return {
                  color: disabled ? "#f5d9ff" : "#252525",
                  fontWeight: "500",
                  backgroundColor: active ? "#eecef9" : undefined,
                };
            },
          }}
        >
          {categories?.map((c) => (
            <NavLink
              className="text-decoration-none text-dark"
              to={`/products/category/${c?._id}`}
            >
              <MenuItem onClick={() => setToggled(false)} className="d-block">
                {c?.name}
              </MenuItem>
            </NavLink>
          ))}

          <NavLink
            className="text-decoration-none text-dark"
            to={`/products`}
          >
            <MenuItem onClick={() => setToggled(false)} className="d-block">
              All Products
            </MenuItem>
          </NavLink>
        </Menu>
        <hr></hr>
        <Menu onClick={() => setToggled(false)}>
          <MenuItem>
            <Link to={"/franchise"} className="side-link fw-semibold text-primary">
              Apply for Franchise
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to={"/About"} className="side-link">
              About Us
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to={"/Contact"} className="side-link">
              Contact Us
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to={"/StoreLocator"} className="side-link">
              Store Locator
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to={"/PrivacyPolicy"} className="side-link">
              Privacy Policy
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to={"/ReturnPolicy"} className="side-link">
              Return Policy
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to={"/termsCondition"} className="side-link">
              Terms & Condition
            </Link>
          </MenuItem>
          {/* <MenuItem><Link to={"/Career"} className='side-link'>Career</Link></MenuItem> */}
          <MenuItem>
            <Link to={"/About"} className="side-link">
              FAQs
            </Link>
          </MenuItem>
        </Menu>
      </Sidebar>

      <nav className="nav-bg main-nav px-lg-5">
        <div className="container-fluid px-lg-5">
          <div className="d-flex navbar-in justify-content-between align-items-center">
            <div className="d-flex justify-content-start">
              <div className="ham-menu pe-2 text-white ">
                <img
                  alt="toggleicon"
                  className="ham-icon"
                  src={ToggleDark}
                  onClick={() => setToggled(!toggled)}
                />
              </div>
              <div className="logo">
                <Link to={"/"}>
                  <img alt="logo" src={MainLogo} />
                </Link>
              </div>
            </div>

            <div className="d-flex">
              <div className="me-3 d-none d-md-block">
                <li className="nav-item pt-2 dropdown">
                  <Link className="nav-link  dropdown-toggle" to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Franchise Application
                  </Link>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li className=""><Link class="dropdown-item" to="/franchise">Apply for Franchise</Link></li>
                  </ul>
                </li>
              </div>
              <ul className="navbar-nav me-2">

                <li className="nav-item">
                  <Link
                    className="text-decoration-none btn cont-btn rounded-0 cont-btn-sm text-white"
                    to={"/become-a-seller"}
                  >
                    Become a seller
                  </Link>
                </li>
              </ul>
              {/* <div className="search-box d-flex align-items-center justify-content-end me-2">
                                                <button className='btn-search'><BsSearch className='icon-dark'/></button>
                                                <input type="text" className='input-search' placeholder="Type to Search..." />
                                          </div> */}
              <div className="d-flex justify-content-end align-items-center text-white">
                <Link to={"/StoreLocator"}>
                  <BsGeoAlt className="icon-dark nav-icon" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default ConSidebar;
