import Omni from '../img/franchise/omni.png'
import Training from '../img/franchise/training.png'
import Inventory from '../img/franchise/inventory.png'
import Business from '../img/franchise/business.png'
import Quality from '../img/franchise/quality.png'


export const FrCardList = [
      {
            image: Omni,
            title: 'Omni Channel Experience',
            description:'Provide a seamless shoping experience including In-store & mobile.'
      },
      {
            image: Training,
            title: 'Store Staff Training',
            description:'Our Robust Training processes ensure our employees are prepared for any situation.'
      },
      {
            image: Business,
            title: 'Business Intelligence',
            description:'We ensures that our partner recieves that maximum at ROI withminimum effort.'
      },
      {
            image: Inventory,
            title: 'Inventory Management',
            description:'Use of best in class modern inventory management solution.'
      },
      {
            image: Quality,
            title: 'Quality & Pricing',
            description:'Our customer centric approach ensures that we offer the best quality of product at great prices.'
      },
      
]