import React, { useState, useEffect } from "react";
import AdminMenu from "../components/AdminMenu";
import axios from "axios";
import { Select } from "antd";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";

const { Option } = Select;
const baseUrl = `${process.env.REACT_APP_API}`;

const CreateProduct = () => {
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);

  // Get all categories
  const getAllCategories = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/v1/category/get-category`
      );
      if (response.data?.success) {
        setCategories(response.data.category);
      }
    } catch (error) {
      console.error("Error fetching categories: ", error);
    }
  };

  // Get all brands
  const getAllBrands = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/v1/brand/get-brand`);
      if (response.data?.success) {
        setBrands(response.data.brand);
      }
    } catch (error) {
      console.error("Error fetching brands: ", error);
    }
  };

  // Get all subcategories
  const getAllSubcategories = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/v1/subcategory/get-subcategory`
      );
      if (response.data?.success) {
        setSubcategories(response.data.subcategory);
      }
    } catch (error) {
      console.error("Error fetching subcategories: ", error);
    }
  };

  useEffect(() => {
    getAllCategories();
    getAllBrands();
    getAllSubcategories();
  }, []);

  const formik = useFormik({
    initialValues: {
      category: "",
      subcategory: "",
      brand: "",
      productname: "",
      productdetails: "",
      price: "",
      sellingprice: "",
      popular: false,
      offer: false,
      photo: null,
    },
    validationSchema: Yup.object({
      category: Yup.string().required("Category is required"),
      subcategory: Yup.string().required("Subcategory is required"),
      brand: Yup.string().required("Brand is required"),
      productname: Yup.string().required("Product Name is required"),
      productdetails: Yup.string().required("Product Details are required"),
      photo: Yup.mixed().required("Photo is required"),
      price: Yup.number()
        .typeError("Price must be a number")
        .required("Price is required")
        .positive("Price must be a positive number"),
      sellingprice: Yup.string()
        .required("selling price is required")
    }),
    onSubmit: async (values) => {
      try {
        const productData = new FormData();
        productData.append("productname", values.productname);
        productData.append("productdetails", values.productdetails);
        productData.append("photo", values.photo);
        productData.append("category", values.category);
        productData.append("brandname", values.brand);
        productData.append("subcategory", values.subcategory);
        productData.append("price", values.price);
        productData.append("sellingprice", values.sellingprice);
        productData.append("popular", values.popular);
        productData.append("offer", values.offer);

        const response = await axios.post(
          `${baseUrl}/api/v1/product/create-product`,
          productData
        );

        if (response.data?.success) {
          formik.resetForm();
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: `${values.productname} Created`,
          });
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: `${values.productname} Failed to create`,
          });
        }
      } catch (error) {
        console.error("Error submitting the form:", error);
      }
    },
  });

  const handleCategoryChange = (value) => {
    console.log("Selected Category:", value);

    formik.setFieldValue("category", value);

    const subcategoriesForCategory = subcategories.filter(
      (sub) => sub?.category?._id === value
    );
    console.log("Filtered Subcategories:", subcategoriesForCategory);

    setFilteredSubcategories(subcategoriesForCategory); // Update the filtered subcategories

    console.log("Updated Subcategories State:", filteredSubcategories);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <AdminMenu />
        <div className="col-md-9 my-4">
          <h4>Create Product</h4>
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label>Select a Category</label>
                  <Select
                    bordered={false}
                    name="category"
                    className="form-control mb-3"
                    placeholder="Select a category"
                    value={formik.values.category}
                    onChange={handleCategoryChange}
                    onBlur={formik.handleBlur}
                  >
                    <Option value="" label="Select a category" />
                    {categories.map((c) => (
                      <Option key={c._id} value={c._id}>
                        {c.name}
                      </Option>
                    ))}
                  </Select>
                  {formik.touched.category && formik.errors.category && (
                    <div className="text-danger">{formik.errors.category}</div>
                  )}

                  <label>Select a Subcategory</label>
                  <Select
                    bordered={false}
                    name="subcategory"
                    placeholder="Select a Subcategory"
                    value={formik.values.subcategory}
                    onChange={formik.handleChange("subcategory")}
                    onBlur={formik.handleBlur}
                    className="form-control mb-3"
                  >
                    {filteredSubcategories.map((sub) => (
                      <Option key={sub?._id} value={sub?._id}>
                        {sub?.subcategoryname}
                      </Option>
                    ))}
                  </Select>
                  {formik.touched.subcategory && formik.errors.subcategory && (
                    <div className="text-danger">
                      {formik.errors.subcategory}
                    </div>
                  )}

                  <label>Select a Brand</label>
                  <Select
                    bordered={false}
                    name="brand"
                    placeholder="Select a Brand"
                    value={formik.values.brand}
                    onChange={formik.handleChange("brand")}
                    onBlur={formik.handleBlur}
                    className="form-control mb-3"
                  >
                    {brands.map((b) => (
                      <Option key={b._id} value={b._id}>
                        {b.brandname}
                      </Option>
                    ))}
                  </Select>
                  {formik.touched.brand && formik.errors.brand && (
                    <div className="text-danger">{formik.errors.brand}</div>
                  )}

                  <input
                    type="text"
                    name="productname"
                    placeholder="Product Name"
                    value={formik.values.productname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="form-control mb-3"
                  />
                  {formik.touched.productname && formik.errors.productname && (
                    <div className="text-danger">
                      {formik.errors.productname}
                    </div>
                  )}

                  <input
                    type="text"
                    name="price"
                    placeholder="Price"
                    value={formik.values.price}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="form-control mb-3"
                  />
                  {formik.touched.price && formik.errors.price && (
                    <div className="text-danger">{formik.errors.price}</div>
                  )}

                  <input
                    type="text"
                    name="sellingprice"
                    placeholder="sellingprice"
                    value={formik.values.sellingprice}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="form-control mb-3"
                  />
                  {formik.touched.sellingprice &&
                    formik.errors.sellingprice && (
                      <div className="text-danger">
                        {formik.errors.sellingprice}
                      </div>
                    )}

                  <textarea
                    name="productdetails"
                    placeholder="Product description"
                    value={formik.values.productdetails}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="form-control mb-3"
                  />
                  {formik.touched.productdetails &&
                    formik.errors.productdetails && (
                      <div className="text-danger">
                        {formik.errors.productdetails}
                      </div>
                    )}

                  <div className="form-check mb-3">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      name="popular"
                      checked={formik.values.popular}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <label className="form-check-label" htmlFor="popular">
                      Popular
                    </label>
                  </div>

                  <div className="form-check mb-3">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      name="offer"
                      checked={formik.values.offer}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <label className="form-check-label" htmlFor="offer">
                      On Offer
                    </label>
                  </div>

                  <button className="btn btn-primary" type="submit">
                    CREATE PRODUCT
                  </button>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label className="btn btn-outline-dark col-md-12">
                    {formik.values.photo
                      ? formik.values.photo.name
                      : "Select Photo"}
                    <input
                      type="file"
                      name="photo"
                      accept="image/*"
                      onChange={(event) => {
                        formik.setFieldValue(
                          "photo",
                          event.currentTarget.files[0]
                        );
                      }}
                      hidden
                    />
                  </label>
                  {formik.touched.photo && formik.errors.photo && (
                    <div className="text-danger">{formik.errors.photo}</div>
                  )}
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  {formik.values.photo && (
                    <div className="text-center">
                      <img
                        src={URL.createObjectURL(formik.values.photo)}
                        alt="product_photo"
                        height={"200px"}
                        className="img img-responsive"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateProduct;
