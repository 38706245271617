import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode, } from "swiper/modules";
import "swiper/css";
import axios from "axios";
import { Link } from "react-router-dom";

const baseUrl = `${process.env.REACT_APP_API}`

const Brands = () => {

  const [brands, setBrands] = useState([]);

  //get all cat
  const getAllBrand = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/api/v1/brand/get-brand`);
      if (data?.success) {
        setBrands(data?.brand);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllBrand();
  }, []);

  return (
    <>

      <div className="row">
        <div className="col-sm-12 ">
          <Swiper
            className="sample-slider"
            // install Swiper modules
            modules={[Autoplay, FreeMode]}
            direction="horizontal"

            loop={true}
            loopFillGroupWithBlank={true}
            autoplay={{
              delay: 1000,
              pauseOnMouseEnter: true,
              disableOnInteraction: false,
            }}
            freeMode={{
              enabled: true,
            }}
            speed={4000}
            breakpoints={{
              400: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
              500: {
                slidesPerView: 4,
                spaceBetween: 20,

              },
              600: {
                slidesPerView: 4,
                spaceBetween: 20,

              },
              768: {
                slidesPerView: 6,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 6,
                spaceBetween: 30,


              }
            }}
          >
            {brands?.map((b) => (
              <SwiperSlide>
                <Link
                  key={b._id}
                  to={`/products/brand/${b._id}`}>
                  <div className="brand-card">
                    <img alt={b.brnadname} className="brand-img" src={`${baseUrl}/api/v1/brand/brand-photo/${b._id}`} />
                  </div>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

      </div>


    </>
  );
};

export default Brands;
