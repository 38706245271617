import React, { useState, useEffect } from "react";
import AdminMenu from "../components/AdminMenu";
import axios from "axios";
import { Select, Modal } from "antd";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";

const { Option } = Select;
const baseUrl = `${process.env.REACT_APP_API}`;

const CreateSubCategory = () => {
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubCategories] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState(null);

  const getAllCategories = async () => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/api/v1/category/get-category`
      );
      if (data?.success) {
        setCategories(data?.category);
      }
    } catch (error) {
      // Handle errors
    }
  };

  const getAllSubCategories = async () => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/api/v1/subcategory/get-subcategory`
      );
      if (data?.success) {
        setSubCategories(data?.subcategory);
      }
    } catch (error) {
      // Handle errors
    }
  };

  useEffect(() => {
    getAllCategories();
    getAllSubCategories();
  }, []);

  const formik = useFormik({
    initialValues: {
      category: "",
      subcategoryname: "",
    },
    validationSchema: Yup.object({
      category: Yup.string().required("Category is required"),
      subcategoryname: Yup.string().required("Subcategory name is required"),
    }),
    onSubmit: async (values) => {
      try {
        const subCategoryData = new FormData();
        subCategoryData.append("category", values.category);
        subCategoryData.append("subcategoryname", values.subcategoryname);

        const { data } = await axios.post(
          `${baseUrl}/api/v1/subcategory/create-subcategory`,
          subCategoryData
        );
        if (data?.success) {
          formik.resetForm();
          displaySuccessMessage(
            `${values.subcategoryname} subcategory Created`
          );
          getAllSubCategories();
        } else {
          displayErrorMessage(`${values.subcategoryname} Failed to create`);
        }
      } catch (error) {
        // Handle errors
      }
    },
  });

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const { data } = await axios.delete(
            `${baseUrl}/api/v1/subcategory/delete-subcategory/${id}`
          );
          if (data.success) {
            displaySuccessMessage("Deleted Successfully");
            getAllSubCategories();
          } else {
            displayErrorMessage("Delete Failed");
          }
        } catch (error) {
          // Handle errors
        }
      }
    });
  };

  const handleUpdate = useFormik({
    initialValues: {
      category: "",
      subcategoryname: "",
    },
    validationSchema: Yup.object({
      category: Yup.string().required("Category is required"),
      subcategoryname: Yup.string().required("Subcategory name is required"),
    }),
    onSubmit: async (values) => {
      try {
        const subCategoryData = new FormData();
        subCategoryData.append("category", values.category);
        subCategoryData.append("subcategoryname", values.subcategoryname);

        const { data } = await axios.put(
          `${baseUrl}/api/v1/subcategory/update-subcategory/${selected._id}`,
          subCategoryData
        );
        if (data?.success) {
          formik.resetForm();
          displaySuccessMessage(`${values.subcategoryname} Updated`);
          getAllSubCategories();
          setVisible(false);
        } else {
          displayErrorMessage(`${values.subcategoryname} Failed to update`);
        }
      } catch (error) {
        // Handle errors
      }
    },
  });

  const displaySuccessMessage = (message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: "success",
      title: message,
    });
  };

  const displayErrorMessage = (message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: "error",
      title: message,
    });
  };

  const handleUpdateCategoryClick = (b) => {
    setSelected(b);
    setVisible(true);
    handleUpdate.setValues({
      category: b.category._id,
      subcategoryname: b.subcategoryname,
    });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <AdminMenu />

        <div className="col-md-9 my-4">
          <h4>Create subcategory</h4>
          <div className="row">
            <div className="col-md-4">
              <form onSubmit={formik.handleSubmit}>
                <label>Select a Category</label>
                <Select
                  bordered={false}
                  className="form-control mb-3"
                  onChange={(value) => formik.setFieldValue("category", value)}
                  value={formik.values.category}
                  optionFilterProp="children"
                  placeholder="Select a category"
                >
                  {categories?.map((c) => (
                    <Option key={c._id} value={c._id}>
                      {c.name}
                    </Option>
                  ))}
                </Select>

                {formik.touched.category && formik.errors.category && (
                  <div className="text-danger">{formik.errors.category}</div>
                )}
                <div className="mb-3">
                  <input
                    type="text"
                    name="subcategoryname"
                    value={formik.values.subcategoryname}
                    placeholder="Enter Subcategory Name"
                    className="form-control"
                    onChange={formik.handleChange}
                  />
                  {formik.touched.subcategoryname &&
                    formik.errors.subcategoryname && (
                      <div className="text-danger">
                        {formik.errors.subcategoryname}
                      </div>
                    )}
                </div>
                <div className="mb-3">
                  <button type="submit" className="btn btn-primary">
                    CREATE SUBCATEGORY
                  </button>
                </div>
              </form>
            </div>
          </div>

          {/* Data Table */}
          <div className="row">
            <div className="col-sm-12">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Category</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {subcategories?.map((b) => (
                    <tr key={b._id}>
                      <td>{b.subcategoryname}</td>
                      <td>{b?.category?.name}</td>
                      <td>
                        <button
                          className="btn btn-sm btn-primary ms-2"
                          onClick={() => {
                            setVisible(true);
                            setSelected(b);
                            handleUpdateCategoryClick(b);
                          }}
                        >
                          Update
                        </button>

                        <button
                          className="btn btn-sm btn-danger ms-2"
                          onClick={() => {
                            handleDelete(b._id);
                          }}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* Update Modal */}
          <Modal
            onCancel={() => setVisible(false)}
            footer={null}
            visible={visible}
          >
            <form onSubmit={handleUpdate.handleSubmit}>
              <Select
                placeholder="Select a category"
                bordered={false}
                className="form-control mb-3"
                onChange={(value) =>
                  handleUpdate.setFieldValue("category", value)
                }
                value={handleUpdate.values.category}
              >
                {categories?.map((c) => (
                  <Option key={c._id} value={c._id}>
                    {c.name}
                  </Option>
                ))}
              </Select>
              {handleUpdate.touched.category &&
                handleUpdate.errors.category && (
                  <div className="text-danger">
                    {handleUpdate.errors.category}
                  </div>
                )}
              <div className="mb-3">
                <input
                  type="text"
                  name="subcategoryname"
                  value={handleUpdate.values.subcategoryname}
                  placeholder="Enter Subcategory Name"
                  className="form-control"
                  onChange={handleUpdate.handleChange}
                />
                {handleUpdate.touched.subcategoryname &&
                  handleUpdate.errors.subcategoryname && (
                    <div className="text-danger">
                      {handleUpdate.errors.subcategoryname}
                    </div>
                  )}
              </div>
              <div className="mb-3">
                <button type="submit" className="btn btn-primary">
                  UPDATE SUBCATEGORY
                </button>
              </div>
            </form>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default CreateSubCategory;
