import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
const Banner = () => {
  return (
    <>
      <Swiper
        pagination={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Pagination, Autoplay]}
        className="mt-5 mt-lg-4"
      >
        <SwiperSlide>
          <picture>
            <source
              media="(min-width:762px)"
              srcset={process.env.PUBLIC_URL + "/img/banner1.jpg"}
            />

            <img
              src={process.env.PUBLIC_URL + "/img/mobilebaner1.jpg"}
              alt="mobilebaner1"
              className="img-fluid"
            />
          </picture>
        </SwiperSlide>
        <SwiperSlide>
          <picture>
            <source
              media="(min-width:762px)"
              srcset={process.env.PUBLIC_URL + "/img/banner2.jpg"}
            />

            <img
              src={process.env.PUBLIC_URL + "/img/mobilebaner2.jpg"}
              alt="mobilebanner2"
              className="img-fluid"
            />
          </picture>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default Banner;
