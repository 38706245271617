import React from "react";
import { Link, NavLink } from "react-router-dom";
import toast from "react-hot-toast";
import { useAuth } from "../../context/auth";

const AdminMenu = () => {
  const [auth, setAuth] = useAuth();
  const handleLogout = () => {
    setAuth({
      ...auth,
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
    toast.success("Logout Successfully");
  };

  return (
    <>
      <nav className="navbar navbar-dark bg-dark">
        <Link className="navbar-brand" to="/admin">
          <div className="container">
            <h4 className="text-center">Admin</h4>
          </div>
        </Link>
      </nav>
      <div className="col-md-2 ps-0">
        <div className="list-group">
          <NavLink
            to="/admin"
            className="list-group-item rounded-0 list-group-item-action"
          >
            Dashboard
          </NavLink>

          <NavLink
            to="/admin/category"
            className="list-group-item list-group-item-action"
          >
            Category
          </NavLink>

          <NavLink
            to="/admin/subCategory"
            className="list-group-item list-group-item-action"
          >
            Sub-Category
          </NavLink>

          <NavLink
            to="/admin/brands"
            className="list-group-item list-group-item-action"
          >
            Brands
          </NavLink>

          <NavLink
            to="/admin/createProduct"
            className="list-group-item list-group-item-action"
          >
            Create Product
          </NavLink>
          <NavLink
            to="/admin/products"
            className="list-group-item rounded-0 list-group-item-action"
          >
            Products
          </NavLink>

          <NavLink
            to="/"
            className="list-group-item list-group-item-action"
            onClick={handleLogout}
          >
            Logout
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default AdminMenu;
