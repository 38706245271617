import React from "react";
import Footer from "../components/Footer";
import Categories from "../components/Categories";
import PopularProducts from "../components/Popular";
import AdBanner1 from "../components/AdBanner1";
import Brands from "../components/Brands";
import Promises from "../components/Promises";
import Banner from "../components/Banner";

const Home = () => {
      return (
            <>
                  <Banner />
                  <Categories />
                  <PopularProducts />
                  <AdBanner1 />
                  <div className="container my-5">
                        <div className="row mb-3">
                              <div className="col-sm-12 ">
                                    <h1 className="text-lg-start sub-heading">Shop by Brands</h1>
                              </div>
                        </div>
                        <Brands />
                  </div>
                  
                  <Promises />
                  <Footer />
            </>
      )
}

export default Home;