import React from "react";

export function FrCard({ image, title, description }) {
  return (
    <div className="col mb-4">
      <div className="card border-0 rounded-0">
        <div className="fr-img-box">
          <img className="img-preview p-3 " alt={title} src={image} />
        </div>
        <div class="card-body">
          <h5 class="card-title">{title}</h5>
          <p class="card-text">{description}</p>
        </div>
      </div>
    </div>
  );
}
