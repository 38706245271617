import React, { useState, useEffect } from "react";
import Footer from "../components/Footer";
import { BsFunnelFill } from "react-icons/bs";
import axios from "axios";
import { Link } from "react-router-dom";

const baseUrl = `${process.env.REACT_APP_API}`;

const Products = () => {
  const [addClass, setAddClass] = useState(false);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [products, setProducts] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const handleButtonClick = () => {
    // Toggle the state to add or remove the class
    setAddClass(!addClass);
  };

  const hideFilter = () => {
    setAddClass(!addClass);
  };

  // Add your handleFilter function here if needed

  const getAllCategory = async () => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/api/v1/category/get-category`
      );
      if (data?.success) {
        setCategories(data?.category);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllBrand = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/api/v1/brand/get-brand`);
      if (data?.success) {
        setBrands(data?.brand);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllProducts = async (pageNum, appendData = false) => {
    try {
      setLoading(true);

      const { data } = await axios.get(
        `${baseUrl}/api/v1/product/product-list/?page=${pageNum}`
      );

      setLoading(false);

      setProducts((prevProducts) => {
        if (pageNum === 1) {
          // If it's the first page, replace the existing products
          return data.products;
        } else {
          // If it's not the first page, append the new products
          return [...prevProducts, ...data.products];
        }
      });

      setHasMore(data.products.length > 0); // Set hasMore based on whether there are more products
    } catch (error) {
      setLoading(false);
      console.error("Error fetching products:", error);
      // Handle the error as needed
    }
  };

  const getTotal = async () => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/api/v1/product/product-count`
      );
      setTotal(data?.total);
    } catch (error) {
      console.log(error);
    }
  };

  const loadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    getAllCategory();
    getAllBrand();
    getAllProducts(page);
    getTotal();
  }, [page]);
  
  return (
    <>
      <div className="container-fluid px-md-5 px-0 px-lg-5 my-5">
        <div className="row mx-0 mx-md-5 mx-lg-5">
          <h4 className="h4 pt-5 mb-5">All Products</h4>
          {/* <p>
            Total items ({total})
          </p> */}
        </div>
        <div className="row mx-0 mx-md-5 mx-lg-5 mb-3">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-10">
                <div className="row">
                  {/* Sorting */}
                  <div className="col-6 col-lg-6 order-lg-1">
                    <div className="d-flex align-items-center justify-content-lg-end justify-content-start d-none">
                      <label style={{ whiteSpace: "pre-wrap" }}>Sort: </label>
                      <select
                        className="sort-products d-lg-inline-block"
                        type="select"
                      >
                        <option value="Relevance">Relevance</option>
                        <option value="Products">New Products</option>
                        <option value="Old Products">Old Products</option>
                      </select>
                    </div>
                  </div>
                  {/* Filter button */}
                  <div className="col-6 col-lg-6 order-lg-0 d-flex justify-content-lg-start justify-content-end align-items-center">
                    <div className="div d-lg-none">
                      <label className="label">Filter: </label>
                      <span className="">
                        <BsFunnelFill onClick={handleButtonClick} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-2 col-sm-12 col-lg-2">
                {/* prodyct filter */}

                {/* <div
                  className={
                    addClass
                      ? "product-filter show-product-filter"
                      : "product-filter"
                  }
                >
                  <div className="filter-body">
                    <div className="row">
                      <div className="col">
                        <h5 className="h5">FILTERS</h5>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <h6 className="">Categories</h6>
                      <div className="row-cols-auto">
                        {categories?.map((c) => (
                          <div className="filter-categories cat">
                            <label>
                              <input
                                type="checkbox"
                                key={c._id}
                                onChange={(e) =>
                                  handleFilter(e.target.checked, c._id)
                                }
                              />
                              <span>{c.name}</span>
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <h6 className="">Brands</h6>
                      <div className="row-cols-auto">
                        {brands?.map((b) => (
                          <div className="filter-categories cat">
                            <label>
                              <input
                                type="checkbox"
                                key={b._id}
                                onChange={(e) =>
                                  handleFilter(e.target.checked, b._id)
                                }
                              />
                              <span>{b.brandname}</span>
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                    <hr />

                    <div className="row d-flex justify-content-evenly">
                      <div className="col">
                        <button
                          type="button"
                          className="btn btn-outline-primary rounded-0"
                          onClick={() => {
                            window.location.reload();
                          }}
                        >
                          Reset{" "}
                        </button>
                      </div>
                      <div className="col d-flex justify-content-end">
                        <button
                          onClick={hideFilter}
                          className="btn btn-primary rounded-0 d-lg-none"
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* filter end */}
              </div>
              <div className="col-md-10 col-lg-10 col-sm-12 col-12 mt-3">
                <div className="row row-cols-2 row-cols-lg-5">
                  {products?.map((p) => (
                    <Link
                      to={`/product/${p.slug}`}
                      className="text-decoration-none"
                    >
                      <div className="col mb-4">
                        <div class="p-2 product-card border-0 rounded-0">
                          {p?.offer ? (
                            <div className="offer-tag py-1 px-2">ON OFFER</div>
                          ) : (
                            ""
                          )}
                          <div className="d-flex product-img-box mb-2 justify-content-center align-items-center">
                            <img
                              alt={p.name}
                              src={`${baseUrl}/api/v1/product/product-photo/${p._id}`}
                              class="img-fluid"
                            />
                          </div>

                          <div class="card-body">
                            <h6 className="product-title">{p.productname}</h6>
                            <div className="">
                              <div className="brand-name">
                                {p?.brandname?.brandname}
                              </div>
                              <div>
                                <span className="selling-price">
                                  ₹ {p.sellingprice}
                                </span>{" "}
                                <span className="mrp"> MRP ₹ {p.price}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* load more button */}
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 text-center">
              {hasMore && (
                <button
                  className="cont-btn btn text-white border-0 rounded-0 bottom-0"
                  onClick={loadMore}
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Load More"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Products;
