import React from 'react';
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import TilesMart from "../img/stores/tilesmart.jpeg"
import Conterials from "../img/stores/conterials.jpeg"
import BannerImg from "../img/about/banner-img.png";

const StoreLocator = () => {
  return (
    <>
      <div className='container-fluid bg-blue-grad mb-5'>
        <div className='row  '>
          <div className="container">
            <div className="row row-col-2 about-banner">
              <div className='col-6 d-flex align-items-center justify-content-start justify-content-lg-center ps-5'>
                <h1 className='text-white'>Our <br /> Stores</h1>
              </div>
              <div className='col-6 d-flex mt-5 justify-content-lg-center justify-content-sm-start'>
                <img alt='banner-img' className='about-banner-img' src={BannerImg} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container'>

        <div className='row lg-row-cols-2 my-4'>
          <div className='col-sm-6 lg-p-5 mb-5'>
            <div className='card border-0 rounded-0 p-4'>
              <div className='mb-4'>

                <img className='img-fluid' src={Conterials} alt="Conterials" />
              </div>
              <div>
                <h4>Conterials.com (Head Office)</h4>

                <li>Address: Near Oxford Public School, Pragati Path, mukchund Toli, Ranchi-834001</li>
                <li>Mobile:- 8789523890</li>
                <li>Opens from 9AM to 9PM</li>

                <li>Email: <Link className='text-decoration-none' to='mailto:conterials.contact@gmail.com'>conterials.contact@gmail.com</Link></li>


              </div>
            </div>
          </div>
          <div className='col-sm-6 lg-p-5'>
            <div className='card border-0 rounded-0 p-4'>
              <div className='mb-4'>
                <img className='img-fluid' src={TilesMart} alt='Tilesmart' />
              </div>
              <div>
                <h4>Tiles Mart (powered by Conterials.com)</h4>

                <li>Addreess: Shop no 4, St Tulsi apartment,
                  near Ranchi Nursing Home,
                  Booty more-834012</li>
                <li>Mobile:- 8092761457, 9955991504</li>
                <li>Opens from 10AM to 8PM</li>
                <li>Email: <Link className='text-decoration-none' to='mailto:aayushbhushan560@gmail.com'> aayushbhushan560@gmail.com</Link></li>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div className="row my-4">
        <div className="col-sm-12 text-center">
          <Link className='btn cont-btn border-0 rounded-0 text-white' to={'/'}>Go to Home</Link>
        </div>
      </div>
      <Footer />

    </>
  )
}

export default StoreLocator;