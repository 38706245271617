import React, { useEffect, useState } from 'react';
import Footer from "../components/Footer";
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
const baseUrl = `${process.env.REACT_APP_API}`

const SingleProduct = () => {

  const params = useParams()
  const [product, setProduct] = useState({});

  //initial details
  useEffect(() => {
    if (params?.slug) getProduct();
    // eslint-disable-next-line
  }, [params?.slug])

  //getproducts
  const getProduct = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/api/v1/product/get-product/${params.slug}`);
      setProduct(data?.product);
    } catch (error) {
      // console.log(error)
    }
  }


  return (
    <>
      <div className="container mt-5">
        <h2 className='h2 pt-5'>
          
        </h2>
      </div>
      <div className='container mb-5'>
        <div className='row'>
          <div className="col-md-5 d-flex justify-content-between align-items-center">
            <div className="single-prod-img p-2 mb-3 d-flex justify-content-center align-items-center">
              <img className='img-fluid' alt={product?.productname} src={`${baseUrl}/api/v1/product/product-photo/${product._id}`} />
            </div>

            {/* {JSON.stringify(product, null, 4)} */}
            {/* <ProdSlider prodimgs={image} /> */}
          </div>
          <div className="col-md-7">
            <div className='row mb-2'>
              <div className='col-8'>
                <div>
                <h2 className='h4'>{product?.productname}</h2>
                </div>
                <div>
                <span className='h4 single-selling-price'><strong>₹ {product.sellingprice}</strong></span> <span className='h5 single-mrp'>MRP ₹ {product.price}</span>
                </div>
                
                
              </div>
              <div className='col-4 d-flex justify-content-end'>
                <div className="single-brand-img d-flex justify-content-end">
                  <img alt={product} className='img-fluid' src={`${baseUrl}/api/v1/brand/brand-photo/${product?.brandname?._id}`} />
                </div>
                
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-6">
                {product?.offer ? (
                  <div className="single-offer my-2 py-2 px-3">ON OFFER</div>
                ) : (
                  ''
                )}
              </div>
              <div className="col-sm-6"></div>
            </div>
            {/* <div className='row mb-2'>
              <div className='col-2 d-flex align-items-center'>
                <div><h6>Size:</h6></div>
              </div>
              <div className='col-10 d-flex justify-content-start'>
                <div className='pe-3'> 2 Meter </div>
                <div className=''> 4 Meter </div>
              </div>
            </div> */}
            <div className='row'>
              <div className='col-6 d-flex justify-content-start'>
                <h6 style={{ whiteSpace: 'pre-wrap' }}>Category : </h6><span>{product?.category?.name}</span>
              </div>
            </div>
            <hr></hr>
            <div className='row my-2'>
              <div className='col'>
                <div className='border-0 rounded-0'>
                  <h6>Description</h6>
                  <p style={{ whiteSpace: 'pre-wrap' }}>{product?.productdetails}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row my-4">
          <div className="col-sm-12 text-center">
            <Link className='btn cont-btn border-0 rounded-0 text-white' to={'/'}>Go to Home</Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default SingleProduct;