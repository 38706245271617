import React from 'react'
import Footer from "../components/Footer";
// import ConatctForm from '../components/ConatctForm';
import { BsGeoAltFill, BsTelephoneFill, BsEnvelopeFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import Brands from '../components/Brands';
import FranchiseFAQs from '../components/FranchiseFAQs';
import FranchiseCard from '../components/FranchiseCard';

const Franchise = () => {
  return (
    <>
      <div className='container-fluid my-5 mt-sm-5 p-0'>
        <picture>
          <source media="(min-width:762px)" srcset={process.env.PUBLIC_URL + "/img/franchise.jpg"} />
          <img src={process.env.PUBLIC_URL + "/img/franchisemobile.jpg"} alt="franchisebanner" className='img-fluid' />
        </picture>
      </div>
      <div className="container my-5">
        <div className="row mb-3">
          <div className="col-sm-12">
            <h2 className="text-lg-start sub-heading">Brands</h2>
          </div>
        </div>
        <Brands />
      </div>

      <div className="container">
        <div className="row">
          <h2 className='sub-heading'>Benifits</h2>
        </div>
        <FranchiseCard />
        <div className="row mb-5">
          <div className="col-sm-6 mb-5">
            <div className="col-sm-12">
              <div className="row mb-4 pt-0 pt-lg-5">
                <h2 className='fw-bold mb-4'>Partner With Us</h2>
                <p className='text-primary h6'>
                  If you have any questions or would like to learn more about becomg a franchisee, please feel free to reah out to us.
                </p>
                <p className='h6'>
                  Our team is here to support you every step of the way.
                </p>
              </div>
              <div className="row py-4 py-sm-4">
                <div className="col-sm-12 d-flex justify-content-start align-items-center">
                  <span className="contact-icon">
                    <BsGeoAltFill />
                  </span>
                  <h6 className='h6 contact-font  mt-3 ms-3'>
                    Pragati Path, Samlong, Ranchi, Jahrkhand -834001
                  </h6>
                </div>

                <div className="col-sm-12 d-flex justify-content-start align-items-center">
                  <span className="contact-icon">
                    <BsTelephoneFill />
                  </span>
                  <h6 className='h6 contact-font  mt-3 ms-3'>
                    <Link className='h6' to="tel:+918789523890">+91 8789523890</Link>
                  </h6>
                </div>

                <div className="col-sm-12 d-flex justify-content-start align-items-center">
                  <div className="contact-icon">
                    <BsEnvelopeFill />
                  </div>
                  <div className='h6 contact-font mt-3 ms-3'>
                    <Link className='h6' to="mailto:conterials.contact@gmail.com">conterials.contact@gmail.com</Link>
                  </div>
                </div>

              </div>
            </div>


          </div>
          <div className="col-sm-6">
            <div className="card border-0">
              <iframe title='franchise-from' className='border-0' src="https://docs.google.com/forms/d/e/1FAIpQLScqDMkLx8WTEDKlIgUiVgEJZ2FvC62e9HQJZcJ_33Kb15C9cQ/viewform?embedded=true" width="auto" height="1080 ">Loading…</iframe>
            </div>
          </div>
        </div>

        <div className="row">
          <FranchiseFAQs />
        </div>


      </div>
      <Footer />
    </>
  )
}

export default Franchise;