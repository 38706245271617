import React from 'react'

const BecomeASeller = () => {
    return (
        <div className='container mb-y'>
            <div className="row" style={{ marginTop: "100px" }}>
                <div className="col-sm-12">
                    <iframe title='become a seller' src="https://docs.google.com/forms/d/e/1FAIpQLSedVgZMnkqC4mRWaRfEkzmYDxLoU__PchHutATrJOTsixYr7w/viewform?embedded=true" width="100%" height="1153" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                </div>
            </div>
        </div>

    )
}

export default BecomeASeller;