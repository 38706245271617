import React from 'react';
import { FrFaqsLists } from './Accordian';
import { FrFaqsList } from '../data/faq';
import FaqImage from '../img/about/faq-image.png';

const FranchiseFAQs = () => {
      return (
            <>
                  <div className='row mb-4'>

                        <div className='col-sm-6'>
                              <img className='img-fluid' src={FaqImage} alt='faq'></img>
                        </div>
                        <div className='col-sm-6'>
                              <h4 className='sub-heading'>
                                    FAQs
                              </h4>

                              <div className='accordian' id='accordianExample'>
                                    {FrFaqsList && FrFaqsList.map((item) =>

                                          <FrFaqsLists
                                                Question={item.Question}
                                                Answer={item.Answer}
                                                HId={item.HId}
                                                BId={item.BId}
                                                Target={item.Target}
                                          />
                                    )}
                              </div>
                        </div>
                  </div>
            </>
      )
}

export default FranchiseFAQs;