import React, { useState, useEffect } from "react";
import AdminMenu from "../components/AdminMenu";
import axios from "axios";
import { Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
const { Option } = Select;

const baseUrl = `${process.env.REACT_APP_API}`;

const UpdateProduct = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [categories, setCategories] = useState([]);
  const [brands, setBands] = useState([]);
  const [subcategories, setSubCategories] = useState([]);
  const [productname, setProductName] = useState("");
  const [productdetails, setProductDetails] = useState("");
  const [category, setCategory] = useState("");
  const [brandname, setBrandName] = useState("");
  const [subcategory, setSubCategory] = useState("");
  const [price, setPrice] = useState("");
  const [sellingprice, setSellingPrice] = useState("");
  const [popular, setPopular] = useState(false);
  const [offer, setOffer] = useState(false);
  const [photo, setPhoto] = useState("");
  const [id, setId] = useState("");

  //get single product
  const getSingleProduct = async () => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/api/v1/product/get-product/${params.slug}`
      );
      setProductName(data.product.productname);
      setId(data.product._id);
      setProductDetails(data.product.productdetails);
      setBrandName(data.product.brandname._id);
      setSubCategory(data.product.subcategory._id);
      setCategory(data.product.category._id);
      setPrice(data.product.price);
      setSellingPrice(data.product.sellingprice);
      setPopular(data.product.popular);
      setOffer(data.product.offer);
      console.log();
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getSingleProduct();
    //eslint-disable-next-line
  }, []);

  //get all category
  const getAllCategory = async () => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/api/v1/category/get-category`
      );
      if (data?.success) {
        setCategories(data?.category);
      }
    } catch (error) {
      // console.log(error);
      // toast.error("Something wwent wrong in getting catgeory");
    }
  };
  //get all subcategory
  const getAllSubCategory = async () => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/api/v1/subcategory/get-subcategory`
      );
      if (data?.success) {
        setSubCategories(data?.subcategory);
      }
    } catch (error) {
      // console.log(error);
      // toast.error("Something went wrong in getting catgeory");
    }
  };
  // get all brand
  const getAllBrand = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/api/v1/brand/get-brand`);
      if (data?.success) {
        setBands(data?.brand);
      }
    } catch (error) {
      // console.log(error);
      // toast.error("Something wwent wrong in getting catgeory");
    }
  };

  useEffect(() => {
    getAllCategory();
    getAllSubCategory();
    getAllBrand();
  }, []);

  //create product function
  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const productData = new FormData();
      productData.append("productname", productname);
      productData.append("productdetails", productdetails);
      photo && productData.append("photo", photo);
      productData.append("category", category);
      productData.append("brandname", brandname);
      productData.append("subcategory", subcategory);
      productData.append("price", price);
      productData.append("sellingprice", sellingprice);
      productData.append("popular", popular);
      productData.append("offer", offer);
      const { data } = axios.put(
        `${baseUrl}/api/v1/product/update-product/${id}`,
        productData
      );
      if (data?.success) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: `${productname} Update Failed`,
        });
        // toast.error(data?.message);
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: `${productname} Update succesfull`,
        });
        // toast.success("Product Update Successfully");
      }
    } catch (error) {
      // console.log(error);
      // toast.error("something went wrong");
    }
    navigate(`/admin/products`);
  };

  const handleDelete = async () => {
    try {
      let answer = window.prompt("Are You Sure want to delete this product ? ");
      if (!answer) return;
      const { data } = await axios.delete(
        `${baseUrl}/api/v1/product/delete-product/${id}`
      );
      if (data.success) {
        // toast.success(`Product is deleted`);
        navigate("/admin/products");
      } else {
        // toast.error(data.message);
      }
    } catch (error) {
      console.log(error);
      // toast.error("Something went wrong");
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <AdminMenu />

        <div className="col-md-9 my-4">
          <h4>Update Product</h4>
          <div className="row">
            <div className="col-md-4">
              <div className="m-1 w-75">
                <label>Choose a Category</label>
                <Select
                  required={true}
                  bordered={false}
                  placeholder="Select a category"
                  className="form-control mb-3"
                  value={category}
                  onChange={(value) => {
                    setCategory(value);
                  }}
                >
                  {categories?.map((c) => (
                    <Option key={c._id} value={c._id}>
                      {c.name}
                    </Option>
                  ))}
                </Select>

                <label>Choose a subcategory</label>
                <Select
                  required={true}
                  bordered={false}
                  placeholder="Select a subcategory"
                  className="form-control mb-3"
                  onChange={(value) => {
                    setSubCategory(value);
                  }}
                  value={subcategory}
                >
                  {subcategories?.map((c) => (
                    <Option key={c._id} value={c._id}>
                      {c.subcategoryname}
                    </Option>
                  ))}
                </Select>

                <label>Choose a Brand</label>
                <Select
                  bordered={false}
                  placeholder="Select a Brand"
                  className="form-control mb-3"
                  onChange={(value) => {
                    setBrandName(value);
                  }}
                  value={brandname}
                >
                  {brands?.map((c) => (
                    <Option key={c._id} value={c._id}>
                      {c.brandname}
                    </Option>
                  ))}
                </Select>

                <div className="mb-3">
                  <input
                    type="text"
                    value={productname}
                    placeholder="Product Name"
                    className="form-control"
                    onChange={(e) => setProductName(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    value={price}
                    placeholder="Price"
                    className="form-control"
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </div>

                {/* Selling Price */}
                <div className="mb-3">
                  <input
                    type="text"
                    value={sellingprice}
                    placeholder="Selling Price"
                    className="form-control"
                    onChange={(e) => setSellingPrice(e.target.value)}
                  />
                </div>

                <div className="mb-3">
                  <textarea
                    type="text"
                    value={productdetails}
                    placeholder="Product description"
                    className="form-control"
                    onChange={(e) => setProductDetails(e.target.value)}
                  />
                </div>
                <label>Popluar</label>
                <br />
                <input
                  type="checkbox"
                  checked={popular}
                  onChange={(e) => setPopular(e.target.checked)}
                />

                <br />
                <br />
                <label>On Offer</label>
                <br />
                <input
                  type="checkbox"
                  checked={offer}
                  onChange={(e) => setOffer(e.target.checked)}
                />

                <div className="my-3">
                  <button className="btn btn-primary" onClick={handleUpdate}>
                    UPDATE PRODUCT
                  </button>
                </div>

                <div className="mb-3">
                  <button className="btn btn-danger" onClick={handleDelete}>
                    DELETE PRODUCT
                  </button>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="mb-3">
                <label className="btn btn-outline-dark col-md-12">
                  {photo ? photo.name : "Select Photo"}
                  <input
                    type="file"
                    name="photo"
                    accept="image/*"
                    onChange={(e) => setPhoto(e.target.files[0])}
                    hidden
                  />
                </label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-3">
                {photo ? (
                  <div className="text-center">
                    <img
                      src={URL.createObjectURL(photo)}
                      alt="product_photo"
                      height={"200px"}
                      className="img img-responsive"
                    />
                  </div>
                ) : (
                  <div className="text-center">
                    <img
                      src={`${baseUrl}/api/v1/product/product-photo/${id}`}
                      alt="product_photo"
                      height={"200px"}
                      className="img img-responsive"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateProduct;
