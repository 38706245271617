import React, { useState, useEffect } from "react";
import Footer from "../components/Footer";
import axios from "axios";
import { Link, useParams } from "react-router-dom";

const baseUrl = `${process.env.REACT_APP_API}`;

const ByBrands = () => {
  const [products, setProducts] = useState([]);
  const [brand, setBrand] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const params = useParams();

  const getAllProductsByBrand = async (page, appendData = false) => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${baseUrl}/api/v1/product/get-product/brand/${params.id}?page=${page}`
      );
      setLoading(false);

      if (appendData) {
        setProducts((prevProducts) => [...prevProducts, ...data.products]);
      } else {
        setProducts(data.products);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching products:", error);
      // Handle the error as needed
    }
  };

  const getBrand = async () => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/api/v1/brand/get-brand/${params.id}`
      );
      if (data?.success) {
        setBrand(data?.brand);
      }
    } catch (error) {
      // Handle the error as needed
    }
  };

  const getTotal = async () => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/api/v1/product/product-count/brand/${params.id}`
      );
      setTotal(data?.total);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line
    getBrand();
    // eslint-disable-next-line
    getTotal();
    // eslint-disable-next-line
  }, [params]);

  useEffect(() => {
    // eslint-disable-next-line
    if (params.id) {
      // eslint-disable-next-line
      getAllProductsByBrand(1);
    }
    // eslint-disable-next-line
  }, [params.id]);

  const loadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    if (page > 1) {
      // eslint-disable-next-line
      getAllProductsByBrand(page, true);
      // eslint-disable-next-line
    }
    // eslint-disable-next-line
  }, [page]);

  return (
    <>
      <div className="container-fluid mt-5 mt-sm-5 p-0">
        <picture>
          <source
            media="(min-width:762px)"
            srcset={process.env.PUBLIC_URL + "/img/stepsbanner.jpg"}
          />

          <img
            src={process.env.PUBLIC_URL + "/img/steps-mobile.jpg"}
            alt="mobilebanner1"
            className="img-fluid"
          />
        </picture>
      </div>

      <div className="container-fluid bg-white">
        <div className="container">
          <div className="row">
            <div className="col-sm-3 col-md-3 col-lg-3">
              <div className="brand-logo-container py-2 d-flex align-items-center">
                <img
                  alt={brand?.brandname}
                  className="brand-logo"
                  src={`${baseUrl}/api/v1/brand/brand-photo/${params.id}`}
                />
              </div>
            </div>
            <div className="col-sm-8"></div>
          </div>
        </div>
      </div>
      <div className="container my-2 my-lg-5">
        <div className="row">
          <div className="col-md-12 col-lg-12 col-sm-12 col-12">
            <div className="row row-cols-2 row-cols-lg-5">
              {products?.map((p) => (
                <Link
                  to={`/product/${p.slug}`}
                  className="text-decoration-none"
                >
                  <div className="col mb-4">
                    <div class="p-2 product-card border-0 rounded-0">
                      {p?.offer ? (
                        <div className="offer-tag py-1 px-2">ON OFFER</div>
                      ) : (
                        ""
                      )}
                      <div className="d-flex product-img-box mb-2 justify-content-center align-items-center">
                        <img
                          alt={p.name}
                          src={`${baseUrl}/api/v1/product/product-photo/${p._id}`}
                          class="img-fluid"
                        />
                      </div>

                      <div class="card-body">
                        <h6 className="product-title">{p.productname}</h6>
                        <div className="">
                          <div className="brand-name">
                            {p?.brandname?.brandname}
                          </div>
                          <div>
                            <span className="selling-price">
                              ₹ {p.sellingprice}
                            </span>{" "}
                            <span className="mrp"> MRP ₹ {p.price}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 text-center">
              {products && products.length < total && (
                <Link
                  className="cont-btn btn text-white border-0 rounded-0 bottom-0"
                  onClick={(e) => {
                    e.preventDefault();
                    loadMore();
                  }}
                >
                  {loading ? "Loading..." : "Load More"}
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ByBrands;
