import React, { useEffect, useState } from "react";
import AdminMenu from "../components/AdminMenu";
import Swal from "sweetalert2";
import axios from "axios";
import { Modal } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";

const baseUrl = `${process.env.REACT_APP_API}`;

const CreateBrand = () => {
  const [brands, setBrands] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState(null);

  //form handle
  const formik = useFormik({
    initialValues: {
      brandname: "",
      photo: null,
    },
    validationSchema: Yup.object({
      brandname: Yup.string().required("Brand name is required"),
      photo: Yup.mixed().required("Brand photo is required"),
    }),
    onSubmit: async (values) => {
      try {
        const brandData = new FormData();
        brandData.append("brandname", values.brandname);
        brandData.append("photo", values.photo);
        const { data } = await axios.post(
          `${baseUrl}/api/v1/brand/create-brand`,
          brandData
        );
        if (data?.success) {
          formik.resetForm();
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: `${values.brandname} Created`,
          });
          getAllBrand();
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: `${values.brandname} Failed to create`,
          });
        }
      } catch (error) {
        // Handle errors
      }
    },
  });

  //get all cat
  const getAllBrand = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/api/v1/brand/get-brand`);
      if (data?.success) {
        setBrands(data?.brand);
      }
    } catch (error) {
      // console.log(error);
      // toast.error("Something wwent wrong in getting catgeory");
    }
  };

  useEffect(() => {
    getAllBrand();
  }, []);

  //delete category
  const handleDelete = async (id) => {
    // Show a confirmation dialog using SweetAlert2
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const { data } = await axios.delete(
            `${baseUrl}/api/v1/brand/delete-brand/${id}`
          );

          if (data.success) {
            // Display success message
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "success",
              title: "Category deleted successfully",
            });

            getAllBrand();
          } else {
            // Display error message
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "error",
              title: "Failed to delete",
            });
          }
        } catch (error) {
          // Handle errors
        }
      }
    });
  };

  const handleUpdateForm = useFormik({
    initialValues: {
      brandname: "",
      photo: null,
    },
    validationSchema: Yup.object({
      brandname: Yup.string().required("Brand name is required"),
      photo: Yup.mixed().required("Brand photo is required"),
    }),
    onSubmit: async (values) => {
      try {
        const brandData = new FormData();
        brandData.append("brandname", values.brandname);
        brandData.append("photo", values.photo);
        const { data } = await axios.put(
          `${baseUrl}/api/v1/brand/update-brand/${selected._id}`,
          brandData
        );
        if (data?.success) {
          formik.resetForm();
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: `${values.brandname} updated`,
          });
          setVisible(false);
          getAllBrand();
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: `${values.brandname} Failed to update`,
          });
        }
      } catch (error) {
        // Handle errors
      }
    },
  });

  const handleUpdateBrandClick = (b) => {
    setSelected(b);
    setVisible(true);
    handleUpdateForm.setValues({
      brandname: b.brandname,
      photo: null, // Reset the photo field to null
    });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <AdminMenu />

        <div className="col-md-9 my-4">
          <h4>Create New brand</h4>
          <br />
          <div className="row">
            <div className="col-md-4">
              <form onSubmit={formik.handleSubmit}>
                <div className="mb-3">
                  <input
                    type="text"
                    name="brandname"
                    value={formik.values.brandname}
                    placeholder="Enter Brand Name"
                    className="form-control"
                    onChange={formik.handleChange}
                  />
                  {formik.touched.brandname && formik.errors.brandname && (
                    <div className="text-danger">{formik.errors.brandname}</div>
                  )}
                </div>

                <div className="mb-3">
                  <label className="btn btn-outline-secondary col-md-12">
                    {formik.values.photo
                      ? formik.values.photo.name
                      : "Select Photo"}
                    <input
                      type="file"
                      name="photo"
                      accept="image/*"
                      onChange={(e) =>
                        formik.setFieldValue("photo", e.target.files[0])
                      }
                      hidden
                    />
                  </label>
                  {formik.touched.photo && formik.errors.photo && (
                    <div className="text-danger">{formik.errors.photo}</div>
                  )}
                </div>

                <div className="mb-3">
                  <button type="submit" className="btn btn-primary">
                    CREATE BRAND
                  </button>
                </div>
              </form>
            </div>

            <div className="col-md-4">
              <div className="mb-3">
                {formik.values.photo && (
                  <div className="text-center">
                    <img
                      src={URL.createObjectURL(formik.values.photo)}
                      alt="product_photo"
                      height={"200px"}
                      className="img img-responsive"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Photo</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {brands?.map((b) => (
                    <>
                      <tr>
                        <td key={b._id}>{b.brandname}</td>
                        <td>
                          <img
                            alt={b.brandname}
                            className="admin-img"
                            src={`${baseUrl}/api/v1/brand/brand-photo/${b._id}`}
                          />
                        </td>
                        <td>
                          <button
                            className="btn btn-sm btn-primary ms-2"
                            onClick={() => {
                              setVisible(true);
                              setSelected(b);
                              handleUpdateBrandClick(b);
                            }}
                          >
                            Update
                          </button>

                          <button
                            className="btn btn-sm btn-danger ms-2"
                            onClick={() => {
                              handleDelete(b._id);
                            }}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
            <Modal
              onCancel={() => setVisible(false)}
              footer={null}
              visible={visible}
            >
              <form onSubmit={handleUpdateForm.handleSubmit}>
                <div className="mb-3">
                  <input
                    type="text"
                    value={handleUpdateForm.values.brandname}
                    placeholder="Brand Name"
                    className="form-control"
                    onChange={(e) =>
                      handleUpdateForm.setFieldValue(
                        "brandname",
                        e.target.value
                      )
                    }
                  />

                  {handleUpdateForm.touched.brandname &&
                    handleUpdateForm.errors.brandname && (
                      <div className="text-danger">
                        {handleUpdateForm.errors.brandname}
                      </div>
                    )}
                </div>

                <div className="mb-3">
                  <label className="btn btn-outline-secondary col-md-12">
                    {handleUpdateForm.values.photo
                      ? handleUpdateForm.values.photo.name
                      : "Select Photo"}
                    <input
                      type="file"
                      name="photo"
                      accept="image/*"
                      onChange={(e) =>
                        handleUpdateForm.setFieldValue(
                          "photo",
                          e.target.files[0]
                        )
                      }
                      hidden
                    />
                  </label>

                  {handleUpdateForm.touched.photo &&
                    handleUpdateForm.errors.photo && (
                      <div className="text-danger">
                        {handleUpdateForm.errors.photo}
                      </div>
                    )}
                </div>

                <div className="col-md-6">
                  <div className="mb-3">
                    {handleUpdateForm.values.photo && (
                      <div className="text-center">
                        <img
                          src={
                            handleUpdateForm.values.photo instanceof File
                              ? URL.createObjectURL(
                                handleUpdateForm.values.photo
                              )
                              : `${baseUrl}/api/v1/brand/brand-photo/${selected._id}`
                          }
                          alt="product_photo"
                          height={"200px"}
                          className="img img-responsive"
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="mb-3">
                  <button type="submit" className="btn btn-primary">
                    UPDATE BRAND
                  </button>
                </div>
              </form>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateBrand;
