export const FaqsList = [
      {
            HId:'headingOne',
            BId:'collapseOne',
            Target:'#collapseOne',
            Question: 'Who are we - Conterials?',
            Answer: 'We aim to bring dynamic changes in the construction materials sector, by establishing an integrated online platform and a network of physical stores, which will offer an extensive selection of products catering to new construction as well as maintenance needs, serving both residential and commercial clients.'
      },
      {
            HId:'headingTwo',
            BId:'collapseTwo',
            Target:'#collapseTwo',
            Question:'What information and documents are needed for business member registration?',
            Answer:'No document is needed.'
      },
      {
            HId:'headingThree',
            BId:'collapseThree',
            Target:'#collapseThree',
            Question:'How can I place an order?',
            Answer:'You can place your order by through call, whatsapp or by visiting the store.'
      },
      {
            HId:'headingFour',
            BId:'collapseFour',
            Target:'#collapseFour',
            Question:'Who will cover the guarantee and warranty of the product?',
            Answer:'The manufacturer will be responsible for providing both guarantee and warranty coverage for the product. For specific details regarding the warranty terms, our customer service desk and call center are readily available to assist you in case you encounter any issues or require support.'
      },
      {
            HId:'headingFive',
            BId:'collapseFive',
            Target:'#collapseFive',
            Question:'Will you have a minimum order quantity for placing an order?',
            Answer:'No minimum order quality.'
      },
      {
            HId:'headingSix',
            BId:'collapseSix',
            Target:'#collapseSix',
            Question:'Will you take returns and do replacements?',
            Answer:'Absolutely, our returns policy allows for returns and replacements in accordance with the stated guidelines. You will have the opportunity to review and understand our returns policy before placing an order for any product. This ensures transparency and clarity in the event that you need to initiate a return or request a replacement.'
      },
      {
            HId:'headingSeven',
            BId:'collapseSeven',
            Target:'#collapseSeven',
            Question:'What are the charges for delivery?',
            Answer:'We offer complimentary delivery services for locations within a 5-kilometer radius from our shop. For orders beyond this distance, delivery charges will apply based on the respective distance.'
      },
  
]



//Franchise FaqList

export const FrFaqsList = [
      {
            HId:'headingOne',
            BId:'collapseOne',
            Target:'#collapseOne',
            Question: 'What is Conterials and and what we do?',
            Answer: `We are one stop solution for all construction needs. First of a kind franchise in Jharkhand, that will connect you directly with the customers without investing heavily in stocks. As a valued franchisee, you'll join a dynamic network of retail outlets and services committed to meeting our customers' ever-changing needs. Together, we'll not only empower local communities but also foster growth and prosperity.`
            
      },
      {
            HId:'headingTwo',
            BId:'collapseTwo',
            Target:'#collapseTwo',
            Question:'Do I need prior experience to become a franchisee?',
            Answer:'No, you do not need prior retail experience to become a franchisee. Anyone with passion to become an entrepreneur and make extra money for long term can be part of Conterials franchise.'
      },
      {
            HId:'headingThree',
            BId:'collapseThree',
            Target:'#collapseThree',
            Question:'How much investment is required to become a franchisee?',
            Answer:'You need an investment of 15-20 lacs to become a franchisee. '
      },
      {
            HId:'headingFour',
            BId:'collapseFour',
            Target:'#collapseFour',
            Question:'How much area is needed to open the franchisee?',
            Answer:'Minimum area is 2000 Sq feet. ( keeping stock in warehouse in not mandatory).'
      },
      {
            HId:'headingFive',
            BId:'collapseFive',
            Target:'#collapseFive',
            Question:'How long does it take to break even?',
            Answer:'You can expect to break even in 15-20 months after opening your Conterials franchise.'
      },
      {
            HId:'headingSix',
            BId:'collapseSix',
            Target:'#collapseSix',
            Question:'What are the benefits of opening a franchisee?',
            Answer:'As an Conterials franchisee, you will have the opportunity to own and operate your own retail outlet and be part of a successful and growing business. You will receive training and support to help you run your business effectively, and benefit from the strength and experience of the Conterials brand. You can be part of the construction material revolution without significant investment in stock.'
      },
      {
            HId:'headingSeven',
            BId:'collapseSeven',
            Target:'#collapseSeven',
            Question:'Where we are accepting franchise application?',
            Answer:'At the moment, Conterials is accepting franchisee applications in Jharkhand only. However, we plan to expand rapidly and serve other parts of the country as well.'
      },
      {
            HId:'headingEight',
            BId:'collapseEight',
            Target:'#collapseEight',
            Question:'How do I apply to become a franchise ?',
            Answer:'To apply for an Conterials franchise, please fill out the franchisee application form. Our team will review your application and get in touch with you to discuss the next steps. Or you can call us to understand more.'
      },
  
]