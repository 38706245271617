import React, { useState, useEffect } from "react";
import { Navigation, Scrollbar, A11y, Grid } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
// import { Categories } from "../data/categories";
import "swiper/css";
import "swiper/css/free-mode";
import axios from "axios";

const baseUrl = `${process.env.REACT_APP_API}`;

const Categories = () => {
  const [categories, setCategories] = useState([]);

  //get all cat
  const getAllCategory = async () => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/api/v1/category/get-category`
      );
      if (data?.success) {
        setCategories(data?.category);
      }
    } catch (error) {
      // console.log(error);
      // toast.error("Something wwent wrong in getting catgeory");
    }
  };

  useEffect(() => {
    getAllCategory();
  }, []);

  return (
    <>
      <div className="container-fluid bg-white py-5">
        <div className="row mb-3 d-lg-none d-md-none">
          <div className="col-sm-12">
            <h2 className="text-md-start sub-heading">Shop by Categories</h2>
          </div>
        </div>
        <div className="container">
          <div className="row mb-3 d-none d-lg-block d-md-block">
            <div className="col-sm-12">
              <h2 className="text-md-start sub-heading">Shop by Categories</h2>
            </div>
          </div>
          <div className="row row-cols-auto d-lg-flex d-none justify-content-center">
            {categories?.map((item) => (
              <Link to={`/products/category/${item._id}`}>
                <div className="cat-card card rounded-0 border-0 mb-4">
                  <div className="categories-image">
                    <img
                      alt={item.name}
                      className="img-fluid"
                      src={`${baseUrl}/api/v1/category/category-photo/${item._id}`}
                    />
                  </div>
                  <div className="img-overlay"></div>
                  <div className="categories-title">{item.name}</div>
                </div>
              </Link>
            ))}
          </div>
        </div>
        <div className="row d-lg-none">
          <Swiper
            // install Swiper modules
            modules={[Navigation, Scrollbar, A11y, Grid]}
            breakpoints={{
              320: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              480: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              768: {
                slidesPerView: 5,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 9,
                spaceBetween: 30,
              },
            }}
            freeMode={true}
          >
            {categories?.map((item) => (
              <SwiperSlide>
                <Link to={`/products/category/${item._id}`}>
                  <div className="cat-card">
                    <div className="categories-image">
                      <img
                        alt={item.name}
                        className="img-fluid"
                        src={`${baseUrl}/api/v1/category/category-photo/${item._id}`}
                      />
                    </div>
                    <div className="img-overlay"></div>
                    <div className="categories-title">{item.name}</div>
                  </div>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default Categories;
