import React, { useState, useEffect } from "react";
import AdminMenu from "../components/AdminMenu";
import axios from "axios";
import { Link } from "react-router-dom";

const baseUrl = `${process.env.REACT_APP_API}`;

const Products = () => {
  const [products, setProducts] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [search, setSearch] = useState('');

  const getAllProducts = async (pageNum, appendData = false) => {
    try {
      setLoading(true);

      const { data } = await axios.get(
        `${baseUrl}/api/v1/product/product-list/?page=${pageNum}&search=${search}`
      );

      setLoading(false);

      setProducts((prevProducts) => {
        if (pageNum === 1) {
          // If it's the first page, replace the existing products
          return data.products;
        } else {
          // If it's not the first page, append the new products
          return [...prevProducts, ...data.products];
        }
      });

      setHasMore(data.products.length > 0); // Set hasMore based on whether there are more products
    } catch (error) {
      setLoading(false);
      console.error("Error fetching products:", error);
      // Handle the error as needed
    }
  };

  const getTotal = async () => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/api/v1/product/product-count`
      );
      setTotal(data?.total);
    } catch (error) {
      console.log(error);
    }
  };

  const loadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {

    getAllProducts(page);
    getTotal();
  }, [page, search]);
  return (
    <div className="container-fluid">
      <div className="row">
        <AdminMenu />

        <div className="col-md-9 my-4">
          <div className="row row-cols-2 mb-5">
            <div className="col-6">
              <h2 className="">All Products</h2>
            </div>
            <div className="col-6 d-flex justify-content-end">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                onChange={({ currentTarget: input }) => setSearch(input.value)}></input>
            </div>
          </div>



          <div className="d-flex row row-cols-2 row-cols-lg-5">
            {products?.map((p) => (
              <div className="col mb-4" key={p._id}>
                <div class="card p-2 product-card border-0 rounded-0">
                  {p?.offer ? (
                    <div className="offer-tag py-1 px-2">ON OFFER</div>
                  ) : (
                    ""
                  )}
                  <div className="d-flex product-img-box mb-2 justify-content-center">
                    <img
                      alt={p.name}
                      src={`${baseUrl}/api/v1/product/product-photo/${p._id}`}
                      class="img-fluid"
                    />
                  </div>
                  <div class="card-body p-2">
                    <h6 class="h6 text-black">{p.productname}</h6>
                    <div class="text-body-tertiary">
                      <div>
                        <strong>Brand:</strong> {p?.brandname?.brandname}
                      </div>
                    </div>
                    <div>
                      <strong>₹ {p.price}</strong>
                    </div>
                  </div>
                  <Link
                    key={p._id}
                    to={`/admin/product/${p.slug}`}
                    className="btn cont-btn text-white"
                  >
                    Edit
                  </Link>
                </div>
              </div>
            ))}
          </div>

        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 text-center">
              {hasMore && (
                <button
                  className="cont-btn btn text-white border-0 rounded-0 bottom-0"
                  onClick={loadMore}
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Load More"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
