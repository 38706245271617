import React from 'react'
import { FrCardList } from '../data/franchise';
import { FrCard } from './FrCard';

const FranchiseCard = () => {
      return (
            
            <div className="row row-cols-2 row-cols-lg-3 d-flex justify-content-center mb-5 my-3">
                  
                  {FrCardList && FrCardList.map((item) =>

                        <FrCard
                              image={item.image}
                              title={item.title}
                              description={item.description}
                        />
                  )}
            </div>
      )
}

export default FranchiseCard;