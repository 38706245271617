import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const baseUrl = `${process.env.REACT_APP_API}`;

const Popular = () => {

  const [products, setProducts] = useState([]);

  //get Pupular products
  const getPopularProducts = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/api/v1/product/popular-product`);
      setProducts(data.products);
    } catch (error) {
      console.log(error);
      // toast.error("Someething Went Wrong");
    }
  };

  //lifecycle method
  useEffect(() => {
    getPopularProducts();
  }, []);

  return (
    <>
      <div className="container py-5">
        <div className="row row-cols-2 row-cols-lg-2">
          <div><h2 className="text-md-start sub-heading">Popular Products</h2></div>
          <div className="d-flex justify-content-end"><Link className="text-decoration-none" to={`/allPopular`}>All Popular</Link></div>
        </div>

        <div className="row row-cols-2 row-cols-lg-5 my-3">
          {products?.map((p) => (

            <Link
              key={p._id}
              to={`/product/${p.slug}`}
              className="display-block text-decoration-none"
            >
              <div className="col mb-4">
                <div class="p-2 product-card border-0 rounded-0">
                  {p?.offer ? (
                    <div className="offer-tag py-1 px-2">ON OFFER</div>
                  ) : (
                    ""
                  )}
                  <div className="d-flex product-img-box mb-2 justify-content-center align-items-center">
                    <img
                      alt={p.name}
                      src={`${baseUrl}/api/v1/product/product-photo/${p._id}`}
                      class="img-fluid"
                    />
                  </div>

                  <div class="card-body">
                    <h6 className="product-title">{p.productname}</h6>
                    <div className="">
                      <div className="brand-name">
                        {p?.brandname?.brandname}
                      </div>
                      <div>
                        <span className="selling-price">
                          ₹ {p.sellingprice}
                        </span>{" "}
                        <span className="mrp"> MRP ₹ {p.price}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>

          ))}
        </div>
        <div className="row">
          <div className="text-center">
            <Link
              className="text-deoration-none cont-btn btn rounded-0 text-white"
              to={"/products"}
            >
              All Products
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Popular;
